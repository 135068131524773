<template>
  <CRow>
    <CModal
        title="View chat detail"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >

      <CCardBody>

        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                {{ $lang.profile.view_chat.sender }}
              </p>
              <p class="custome-detail">
                {{ chat.sender }}
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="12" v-if="chat.isDoc===0">
              <p class="custome-labal">
                {{ $lang.profile.view_chat.chat }}
              </p>
              <p class="custome-detail">
                {{ chat.chat }}
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="12" v-if="chat.isDoc===1">
              <p class="custome-labal">
                {{ $lang.profile.view_chat.attachment }}
              </p>
              <p class="custome-detail">
                <a :href="chat.url" target="_blank">
              <span v-show="chat.thumbnail" v-if="chat.thumbnail">
                <img :src="chat.thumbnail" width="100" height="100">
              </span>
                  <span v-else>
                 {{ $lang.profile.view_chat.attachment }}
              </span>
                </a>
              </p>
            </CCol>


            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                {{ $lang.profile.view_chat.isRead }}
              </p>
              <p class="custome-detail">
                <CBadge :color="statusBudget(chat.isRead)">{{ chat.isRead }}</CBadge>
              </p>
            </CCol>


            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                {{ $lang.profile.view_chat.created }}
              </p>
              <p class="custome-detail">
                {{ dateTimeUTCtoLocalFormat(chat.createdAt) }}
              </p>
            </CCol>

          </CRow>
        </CCol>


      </CCardBody>
      <CCardFooter>
      </CCardFooter>

    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <back-to-list></back-to-list>

          <div class="row">
            <CCol sm="6" class="align-self-center">
              {{ self.groupTitle }}
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">


                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Poll Chats',self.roomId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>

          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>
                #{{ self.groupPostType }} - #{{ self.groupPostCategory }} <br>
                {{ self.groupPostMessage }}
              </strong>
            </CCol>

          </div>

        </CCardHeader>
        <CCardBody>
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="
                exports(self, 'Excel', module, 'Poll Chats',self.roomId)
              "
          >
            <template #isRead="data">
              <CBadge :color="statusBudget(data.row.isRead)">{{ data.row.isRead }}</CBadge>
            </template>
            <template #chat="data">
                                    <span :title="trimfunction(data.row.chat,50)">
                                      {{ trimfunction(data.row.chat, 50) }}
                                    </span>
            </template>
            <template #sender="data">
              <Avatar
                  :image="data.row.senderProfile"
                  :content="data.row.sender"
                  :isProfileListPage="true"
                  :length="20"
              />
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-on:click="viewChat(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
import BackToList from "../../backToList";


export default {
  name: "postChat",
  mixins: [Mixin],
  components: {
    BackToList,
    VueJsonToCsv,
    Avatar
  },
  data() {
    return {
      roomId: '',
      groupPostCategory: '',
      groupPostType: '',
      groupPostMessage: '',
      groupTitle: '',
      submitted: false,
      listUrl: '/users/groups/categories/chat/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      chat: {
        chat: "",
        thumbnail: "",
        isDoc: "",
        isRead: "",
        sender: "",
        senderProfile: "",
        createdAt: "",
        url: "",
        senderId: "",
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["sender", "chat", "isRead", "createdAt", "actions"],
      data: [],
      options: {
        headings: {
          sender: this.$lang.profile.table.chat.sender,
          chat: this.$lang.profile.table.chat.chat,
          isRead: this.$lang.profile.table.chat.isRead,
          createdAt: this.$lang.profile.table.chat.created,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["sender"],
        sortable: ["sender"],
        filterable: ["sender"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
    },
  },

  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.roomId = this.$route.params.id;
    if (this.$route.params.id) {
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.id;
    }
    self.groupPostCategory = localStorage.getItem('groupPostCategory');
    self.groupPostType = localStorage.getItem('groupPostType');
    self.groupPostMessage = localStorage.getItem('groupPostMessage');
    self.groupTitle = localStorage.getItem('groupTitle');
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.countryId = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
    viewChat(id) {
      let self = this;
      axios.get('users/groups/categories/chat/view/' + id).then((response) => {
        if (response) {
          let responseData = response.data.data;
          self.chat.chat = responseData.chat;
          self.chat.url = responseData.url;
          self.chat.thumbnail = responseData.thumbnail;
          self.chat.isDoc = responseData.isDoc;
          self.chat.isRead = responseData.isRead;
          self.chat.sender = responseData.sender;
          self.chat.senderProfile = responseData.senderProfile;
          self.chat.createdAt = responseData.createdAt;
          self.chat.senderId = responseData.senderId;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },

  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
